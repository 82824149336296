import React from "react";
import { Layout } from "../components";
import { SvgArrow } from "../components";
import { StaticQuery, graphql, Link } from "gatsby";

const Referencje = ({ referencesPosts }) => {
  return (
    <Layout
      seo={{
        title: "Referencje",
        href: "pl",
        lang: "pl"
      }}
    >
      <section className="container-fluid testimonials_section_1">
        <div className="text-center">
          <h1>Referencje</h1>
        </div>
        <div className="agency_grid reference">
          {referencesPosts.edges.map(({ node }) => (
            <Link
              className="agency_grid__item"
              to={`/referencje/${node.slug}`}
              key={node.id}
            >
              <div>
                <div className="agency_grid__item-logo">
                  <img
                    className="img-fluid bigger"
                    src={node.better_featured_image.source_url}
                    alt=""
                  />
                </div>
                <div
                  className="agency_grid__item-description"
                  dangerouslySetInnerHTML={{ __html: node.excerpt }}
                />
              </div>
              <div className="agency_grid__item-button">
                <SvgArrow />
              </div>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      {
        referencesPosts: allWordpressWpReferences(
          filter: { acf: { reference_show_in_feed: { in: true } } }
        ) {
          edges {
            node {
              id
              excerpt
              slug
              better_featured_image {
                source_url
              }
            }
          }
        }
      }
    `}
    render={data => <Referencje {...data} />}
  />
);
